import { Link, useLocation } from 'react-router-dom';

function Navbar(props) {
    const setLang = (lang) => props.setLang(lang);

    return (
        <nav className="navbar navbar-expand-lg shadow-lg bg-white" style={{width: "100%"}}>
            <a className="navbar-brand" href="/">
                <img className="ms-5" src="assets/img/logo.png" height={"55px"} alt="Bredsa logistics" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto me-5 mb-2 mb-lg-0 fw-semibold">
                    <li className="nav-item">
                        <Link to="servicios" className={(useLocation().pathname === "/servicios") ? "nav-link fw-bold text-color2" : "nav-link fw-bold text-color1"}>{props.lang === "es" ? "SERVICIOS" : "SERVICES"}</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="sectores" className={(useLocation().pathname === "/sectores") ? "nav-link fw-bold text-color2" : "nav-link fw-bold text-color1"}>{props.lang === "es" ? "SECTORES" : "SECTORS"}</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='contacto' className={(useLocation().pathname === "/contacto") ? "nav-link fw-bold text-color2" : "nav-link fw-bold text-color1"}>{props.lang === "es" ? "CONTACTO" : "CONTACT"}</Link>
                    </li>
                    <li className="nav-item">
                        <small className="nav-link">
                            <span style={{cursor: "pointer"}} onClick={() => setLang('es')} className={(props.lang === "es") ? "text-color2" : "text-color1"}>ESP</span> · 
                            <span style={{cursor: "pointer"}} onClick={() => setLang('en')} className={(props.lang === "en") ? "text-color2" : "text-color1"}>ENG</span>
                        </small>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
