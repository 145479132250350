function Home(props) {
  return (
    <div className="mb-5">
      <div className="row d-none d-md-block pt-3" style={{height: "420px", backgroundImage: "url('assets/img/home-1.jpg')"}}>
        <div className="col-md-5 offset-md-2 text-white">
          <p className="fw-semibold text-start mt-5" style={{fontSize: "40px"}}>{
              props.lang === "es" ?
              "Somos una empresa mexicana con más de 20 años de experiencia." :
              "We are a Mexican company with more than 20 years of experience."
            }
          </p>
          <p className="text-start mt-5">{
              props.lang === "es" ?
              "Proveemos soluciones logísticas que contribuyen al éxito de nuestros clientes." :
              "We promote logistics solutions that contribute to the success of our clients"
            }
          </p>
        </div>
      </div>
      <div className="row d-md-none" style={{height: "330px", backgroundImage: "url('assets/img/home-0.png')", backgroundColor: "rgba(50,40,155, 0.3)", backgroundSize: "cover", backgroundPosition: "right"}}>
      </div>
      <div className="row d-md-none bg-color2 p-3 px-4">
        <div className="col-md-5 offset-md-2 text-white">
          <p className="fw-semibold text-start mt-5" style={{fontSize: "30px"}}>{
              props.lang === "es" ?
              "Somos una empresa mexicana con más de 20 años de experiencia." :
              "We are a Mexican company with more than 20 years of experience."
            }
          </p>
          <p className="text-start mt-5">{
              props.lang === "es" ?
              "Proveemos soluciones logísticas que contribuyen al éxito de nuestros clientes." :
              "We promote logistics solutions that contribute to the success of our clients"
            }
          </p>
        </div>
      </div>
      <div className="text-center">
        <img src="assets/img/home-logo.png" style={{height: "85px", marginTop: "-40px"}} alt="logo"/>
      </div>
      <div className="container">
        <h5 className="fw-bold my-4">{props.lang === "es" ? "Nuestro compromiso" : "Our commitment"}</h5>
        <div className="row">
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-2.png')" : "url('assets/img/home-2-eng.png')"
          }}>
          </div>
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-3.png')" : "url('assets/img/home-3-eng.png')"
          }}>
          </div>
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-4.png')" : "url('assets/img/home-4-eng.png')"
          }}>
          </div>
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-5.png')" : "url('assets/img/home-5-eng.png')"
          }}>
          </div>
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-6.png')" : "url('assets/img/home-6-eng.png')"
          }}>
          </div>
          <div className="col-md-4" style={{
            height: "180px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: props.lang === "es" ? "url('assets/img/home-7.png')" : "url('assets/img/home-7-eng.png')"
          }}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
