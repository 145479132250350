function Sectores(props) {
  return (
    <div className="text-center pt-5 pb-5">
      <p className="fw-semibold" style={{ fontSize: "40px" }}>{props.lang === "es" ? "Sectores que atendemos" : "Industries and sectors"}</p>
      <img style={{ width: "140px" }} src="assets/img/divisor.png" alt=""/>
      <div className="container mt-5">
          <div className="d-none d-md-block" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-1.png')" : "url('assets/img/sectores-1-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-none d-md-block" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-2.png')" : "url('assets/img/sectores-2-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-none d-md-block" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-3.png')" : "url('assets/img/sectores-3-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-none d-md-block" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-4.png')" : "url('assets/img/sectores-4-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-md-none" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-1-small.png')" : "url('assets/img/sectores-1-small-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-md-none" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-2-small.png')" : "url('assets/img/sectores-2-small-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-md-none" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-3-small.png')" : "url('assets/img/sectores-3-small-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
          <div className="d-md-none" style={{
            backgroundImage: props.lang === "es" ? "url('assets/img/sectores-4-small.png')" : "url('assets/img/sectores-4-small-eng.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300px" }}>
          </div>
      </div>
    </div>
  );
}

export default Sectores;
