import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <div className='bg-color4' style={{width: "100%"}}>
            <div className="container pt-5">
                <div className="row">
                    <div className="d-none d-md-block col-md-6 text-start">
                        <div><img src="assets/img/footer-logo.png" style={{width: "180px"}} alt="Bredsa Logistics"/></div>
                        <p className="mt-5 text-white"><strong>{(new Date()).getFullYear()} ® Bredsa Logistics</strong> · WABI Solutions/JOY</p>
                    </div>
                    <div className="d-md-none col-12 text-center mb-5">
                        <div><img src="assets/img/footer-logo.png" style={{width: "180px"}} alt="Bredsa Logistics"/></div>
                    </div>
                    <div className="col-8 offset-2 col-md-2 offset-md-0">
                        <p className="text-white fw-bold">{props.lang === "es" ? "REDES SOCIALES" : "SOCIAL NETWORKS"}</p>
                        <div className="row">
                            <div className="col-4">
                                <a href="https://www.facebook.com/profile.php?id=61553068525671&mibextid=LQQJ4d"><img src="assets/img/fb-logo.png" style={{height:"25px"}} alt='Facebook logo'/></a>
                            </div>
                            <div className="col-4">
                                <a href="https://www.instagram.com/bredsa_logistics"><img src="assets/img/ig-logo.png" style={{height:"25px"}} alt='Instagram logo'/></a>
                            </div>
                            <div className="col-4">
                                <a href="https://wa.me/5529052447"><img src="assets/img/wa-logo.png" style={{height:"25px"}} alt='WhatsApp logo'/></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 offset-1 mt-5 col-md-2 offset-md-0 text-start">
                        <Link to="/" className='text-decoration-none'>
                            <img style={{height: "15px"}} src="assets/img/footer-list.png" alt=""/>
                            <span className="fw-semibold text-white">{props.lang === "es" ? "INICIO" : "HOME"}</span>
                        </Link><br/>
                        <Link to="sectores" className='text-decoration-none'>
                            <img style={{height: "15px"}} src="assets/img/footer-list.png" alt=""/>
                            <span className="fw-semibold text-white">{props.lang === "es" ? "SECTORES" : "SECTORS"}</span>
                        </Link>
                    </div>
                    <div className="col-6 mt-5 col-md-2 offset-md-0 text-start">
                        <Link to="servicios" className='text-decoration-none'>
                            <img style={{height: "15px"}} src="assets/img/footer-list.png" alt=""/>
                            <span className="fw-semibold text-white">{props.lang === "es" ? "SERVICIOS" : "SERVICES"}</span>
                        </Link><br/>
                        <Link to="contacto" className='text-decoration-none'>
                            <img style={{height: "15px"}} src="assets/img/footer-list.png" alt=""/>
                            <span className="fw-semibold text-white">{props.lang === "es" ? "CONTACTO" : "CONTACT"}</span>
                        </Link>
                    </div>
                    <div className="d-md-none col-12 text-center">
                        <p className="mt-5 text-white"><strong>{(new Date()).getFullYear()} ® Bredsa Logistics</strong> · WABI Solutions/JOY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
