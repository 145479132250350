import React, { useState } from 'react';
import axios from "axios";

function Contacto(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  function send() {
    axios.post("https://api.ciroglobal.com/mail/bredsa", {
      name, phone, message
    }).then(
      response => {
        console.log("Response", response);
      }
    ).catch(
      error => {
        console.log(error);
      }
    );
  }

  function setValue(evt, field){
    switch(field){
      case "name": setName(evt); break;
      case "phone": setPhone(evt); break;
      case "message": setMessage(evt); break;
      default: break;
    }
  }

  return (
    <div className="text-center pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-none d-md-block">
            <img src="assets/img/contacto.png" className="img-fluid" alt="" />
          </div>
          <div className="col-12 col-md-6 text-start">
            <p className="fw-semibold" style={{ fontSize: "40px" }}>{props.lang === "es" ? "Contáctanos" : "Contact us"}</p>
            <img style={{ width: "140px" }} src="assets/img/divisor.png" alt="" />
            <img src="assets/img/contacto.png" className="img-fluid d-md-none" alt="" />
            <div className="mt-5">
              <p>{props.lang === "es" ? "Oficina" : "Office"}<br />
                <span className='d-none d-md-block' style={{ fontSize: "28px" }}>55-2905-2447</span>
                <span className="d-md-none" style={{ fontSize: "20px" }}>55-2905-2447</span>
              </p>
            </div>
            <div className="mt-5">
              <p>{props.lang === "es" ? "Correo de atención al cliente:" : "Customer service email"}<br />
                <span className='d-none d-md-block' style={{ fontSize: "28px" }}>administracion@bredsa.mx</span>
                <span className="d-md-none" style={{ fontSize: "20px" }}>administracion@bredsa.mx</span>
              </p>
            </div>
            <div className="mt-5">
              <p>{props.lang === "es" ? "Enviar un correo:" : "Send an email:"}</p>
              <input className="form-control mt-3" onChange={e => setValue(e.target.value, "name")} type="text" placeholder={props.lang === "es" ? "Nombre" : "Name"} />
              <input className="form-control mt-3" onChange={e => setValue(e.target.value, "phone")} type="text" placeholder={props.lang === "es" ? "Teléfono de contacto" : "Phone"} />
              <textarea className="form-control mt-3" onChange={e => setValue(e.target.value, "message")} placeholder={props.lang === "es" ? "Comentarios" : "Comments"}></textarea>
              <div className="text-end">
                <button className="btn bg-color2 text-white mt-3" onClick={e => send()}>{props.lang === "es" ? "Enviar" : "Send"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
