import './App.css';
import Navbar from "./Shared/Navbar/Navbar";
import Footer from "./Shared/Footer/Footer";
import Home from "./Home/Home";
import Contacto from "./Contacto/Contacto";
import Sectores from "./Sectores/Sectores";
import Servicios from "./Servicios/Servicios";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';

function App() {
  const [lang, setLang] = useState('es');
  const changeLang = (newLang) => setLang(newLang);

  return (
    <div className="App bg-color3">
      <BrowserRouter>
        <Navbar lang={lang} setLang={changeLang} />
        <Routes>
          <Route path='/' element={<Home lang={lang} />} />
          <Route path='/contacto' element={<Contacto lang={lang} />} />
          <Route path='/sectores' element={<Sectores lang={lang} />} />
          <Route path='/servicios' element={<Servicios lang={lang} />} />
        </Routes>
        <Footer lang={lang} />
      </BrowserRouter>
    </div>
  );
}

export default App;
