function Servicios(props) {
    return (
        <div className="text-center pt-5 pb-5">
            <p className="fw-semibold" style={{fontSize: "40px"}}>{props.lang === "es" ? "Líneas de negocio" : "Business Sectors"}</p>
            <img style={{width: "140px"}} src="assets/img/divisor.png" alt=""/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6" style={{
                        backgroundImage: props.lang === "es" ? "url('assets/img/servicios-1.png')" : "url('assets/img/servicios-1-eng.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                    <div className="col-md-6" style={{
                        backgroundImage: props.lang === "es" ? "url('assets/img/servicios-2.png')" : "url('assets/img/servicios-2-eng.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                    <div className="col-md-6" style={{
                        backgroundImage: "url('assets/img/servicios-3.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                    <div className="col-md-6" style={{
                        backgroundImage: props.lang === "es" ? "url('assets/img/servicios-4.png')" : "url('assets/img/servicios-4-eng.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                    <div className="col-md-6" style={{
                        backgroundImage: "url('assets/img/servicios-5.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                    <div className="col-md-6" style={{
                        backgroundImage: props.lang === "es" ? "url('assets/img/servicios-6.png')" : "url('assets/img/servicios-6-eng.png')",
                        backgroundSize:"contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px"}}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Servicios;
